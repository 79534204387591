import { useQuery } from "@tanstack/react-query";

import { PATIENT_STATUS } from "~/common/constants";
import { getCurrentScreeningBundle } from "~/patients/api";
import { useGetCurrentPatient } from "~/patients/hooks";

export const useGetCurrentScreeningBundle = () => {
  const { data: user, isFetching: isUserFetching } = useGetCurrentPatient();

  const consultationId = user?.consultation?.id;

  const enabled =
    !isUserFetching && !!user && user.status !== PATIENT_STATUS.active;

  const { data, isLoading, isFetching } = useQuery({
    queryFn: () => getCurrentScreeningBundle(consultationId),
    queryKey: ["getCurrentScreeningBundle", consultationId],
    enabled,
  });

  return { data, isLoading, isFetching };
};
