import { isPatientActive, PatientStatus } from "~/common/utils";
import { ROUTES } from "../router";

export const getAssessmentAndPlanRoute = (patientStatus: PatientStatus) =>
  isPatientActive(patientStatus)
    ? ROUTES.patientComplete
    : ROUTES.consultationComplete;

export const getTeamMemberConsultationBaseUrl = (consultationId?: string) =>
  `/v1/team-members/consultations/${consultationId}`;

export const getConsultationBaseUrl = (consultationId?: string) =>
  `/v1/consultations/${consultationId}`;

export const getPatientIntakePath = (
  intakeId: string,
  consultationId?: string,
) => `${getConsultationBaseUrl(consultationId)}/intakes/${intakeId}`;

export const getPatientScreeningPath = (
  screeningId: string,
  consultationId?: string,
) => `${getConsultationBaseUrl(consultationId)}/screenings/${screeningId}`;
