import React, { ComponentPropsWithoutRef, ForwardedRef } from "react";
import { twMerge } from "tailwind-merge";

import { RADIO_BUTTON_VARIANTS } from "~/common/constants";

export type RadioButtonOptionVariant = keyof typeof RADIO_BUTTON_VARIANTS;
interface RadioButtonOptionProps extends ComponentPropsWithoutRef<"input"> {
  label: string;
  containerClassName?: string;
  isSelected?: boolean;
  variant?: RadioButtonOptionVariant;
  labelClassName?: string;
}

const RadioButtonOption = React.forwardRef(
  (
    {
      label,
      id,
      className,
      containerClassName,
      isSelected,
      variant = "button",
      labelClassName,
      children,
      ...props
    }: RadioButtonOptionProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <label
        htmlFor={id}
        className={twMerge(
          "flex w-full items-center gap-1 px-1 transition-all duration-300 ease-in-out",
          props.disabled ? "cursor-default" : "cursor-pointer",
          (variant === RADIO_BUTTON_VARIANTS.button ||
            variant === RADIO_BUTTON_VARIANTS.left_radio) &&
            "w-full items-center justify-between rounded-lg border border-neutral-700 bg-white px-4 py-3",
          isSelected &&
            (variant === RADIO_BUTTON_VARIANTS.button ||
              variant === RADIO_BUTTON_VARIANTS.left_radio) &&
            "border-primary-500 bg-primary-200 shadow-md",
          containerClassName,
        )}
      >
        <span
          className={twMerge(
            "w-full font-normal text-gray-700 first-letter:capitalize",
            labelClassName,
            (variant === RADIO_BUTTON_VARIANTS.simple ||
              variant === RADIO_BUTTON_VARIANTS.left_radio) &&
              "hidden",
          )}
        >
          {label}
        </span>
        <input
          ref={ref}
          id={id}
          type="radio"
          className={twMerge(
            "size-5 border-2 border-neutral-700 bg-neutral-25 text-primary-500 focus:ring-2 focus:ring-primary-500",
            "disabled:text-gray-300",
            variant === RADIO_BUTTON_VARIANTS.simple && "size-4 focus:ring-1",
            className,
          )}
          {...props}
        />
        <span
          className={twMerge(
            "w-full text-sm text-gray-70 first-letter:capitalize",
            variant === RADIO_BUTTON_VARIANTS.button && "hidden",
            variant === RADIO_BUTTON_VARIANTS.left_radio &&
              "text-base font-normal first-letter:capitalize",
          )}
        >
          {label}
        </span>
        {children}
      </label>
    );
  },
);
RadioButtonOption.displayName = "RadioButtonOption";
export default RadioButtonOption;
