import { useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import { ConsultationCompleted } from "~/patients/components/consultations/shared";
import {
  useGetCurrentIntakeBundle,
  useGetCurrentPatient,
} from "~/patients/hooks";
import { useCompleteIntake } from "~/patients/hooks/queries/consultations/intake/useCompleteIntake";

interface ScreeningCompletedScreenProps {
  handleGoBackFinalScreen: () => void;
}
export const IntakeCompletedScreen = ({
  handleGoBackFinalScreen,
}: ScreeningCompletedScreenProps) => {
  const { data: user } = useGetCurrentPatient();
  const consultationId = user?.consultation?.id;

  const { profile_picture_path: providerPictureSrc } = { ...user?.provider };
  const { id: patientIntakeId } = useParams();
  const { completeIntakeMutation, isPending } = useCompleteIntake();
  const { data, isLoading, isFetching } = useGetCurrentIntakeBundle();

  if (!data) return;

  const { sections_completed, total_sections } = data;
  const sectionCompleted = (sections_completed ?? 0) + 1;
  const isFinalIntake = sectionCompleted === total_sections;

  const title = `You’ve completed Section ${sectionCompleted}!`;

  const subtitle = `You can’t edit your answers once you submit your request.`;

  const handleCompleteIntake = () =>
    patientIntakeId &&
    completeIntakeMutation({ id: patientIntakeId, consultationId });

  if (isFetching) return <LoadingState />;

  return (
    <ConsultationCompleted
      handleGoBackFinalScreen={handleGoBackFinalScreen}
      title={title}
      subtitle={subtitle}
      isFinal={isFinalIntake}
      providerPictureSrc={providerPictureSrc}
      isLoading={isLoading}
      isPending={isPending}
      handleComplete={handleCompleteIntake}
    />
  );
};
