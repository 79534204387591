import { Navigate, useNavigate } from "react-router-dom";

import { Header, LoadingState, TransitionEffect } from "~/common/components";
import { isPatientActive } from "~/common/utils";
import { IntakeCheckList } from "~/patients/components";
import { useGetCurrentIntakeBundle } from "~/patients/hooks";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { ROUTES } from "~/patients/router";
import { getHomePath } from "~/patients/utils";

export const CheckListScreenIntake = () => {
  const { data: user, isFetching: isUserFetching } = useGetCurrentPatient();
  const navigate = useNavigate();
  const homePath = getHomePath(user?.status);

  const { data, isLoading } = useGetCurrentIntakeBundle();

  if (isLoading || isUserFetching) return <LoadingState />;

  if (isPatientActive(user?.status) || !data)
    return <Navigate to={ROUTES.notFound} />;

  const subtitle = `${data.sections_completed}/${data.total_sections} sections completed`;

  return (
    <TransitionEffect className="flex h-screen-calculated w-full flex-col text-sm">
      <Header
        title="Help me understand your story."
        subtitle={subtitle}
        handleGoBack={() => navigate(homePath)}
      />
      <IntakeCheckList {...data} />
    </TransitionEffect>
  );
};
