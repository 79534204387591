import { Navigate } from "react-router-dom";

import { LoadingState } from "~/common/components";
import { isPatientActive } from "~/common/utils";
import { useGetCurrentPatient } from "~/patients/hooks/queries";
import { LeadPayment } from "../components/consultations/payments";
import { ROUTES } from "../router";

export const Payment = () => {
  const { data: user, isFetching } = useGetCurrentPatient();

  if (isFetching) return <LoadingState />;

  if (isPatientActive(user?.status)) return <Navigate to={ROUTES.notFound} />;

  return <LeadPayment />;
};
