import { Navigate, useNavigate } from "react-router-dom";

import { Header, LoadingState, TransitionEffect } from "~/common/components";
import { isPatientActive } from "~/common/utils";
import { ScreeningCheckList } from "~/patients/components/consultations";
import {
  useGetCurrentPatient,
  useGetCurrentScreeningBundle,
} from "~/patients/hooks";
import { ROUTES } from "~/patients/router";
import { getHomePath } from "~/patients/utils";

export const CheckListScreenScreening = () => {
  const { data: user } = useGetCurrentPatient();
  const navigate = useNavigate();
  const homePath = getHomePath(user?.status);

  const { data, isLoading } = useGetCurrentScreeningBundle();

  if (isLoading) return <LoadingState />;

  if (isPatientActive(user?.status) || !data)
    return <Navigate to={ROUTES.notFound} />;

  const subtitle = `${data.sections_completed}/${data.total_sections} sections completed`;

  return (
    <TransitionEffect className="flex h-screen-calculated w-full flex-col text-sm">
      <Header
        title="Help me understand your story."
        subtitle={subtitle}
        handleGoBack={() => navigate(homePath)}
      />
      <ScreeningCheckList {...data} />
    </TransitionEffect>
  );
};
