import { useQuery } from "@tanstack/react-query";

import { validateProviderId } from "~/providers/api";

export const useValidateProviderId = (
  providerId?: string,
  enabled?: boolean,
) => {
  const { isFetching } = useQuery({
    queryFn: () => validateProviderId(providerId ?? ""),
    queryKey: ["validateProviderId", providerId],
    retry: 1,
    enabled: !!providerId && enabled,
  });
  return { isFetching };
};
