import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { LoadingState } from "~/common/components";
import { isPatientActive } from "~/common/utils";
import {
  CHECKIN_STATUS,
  CreatedByProviderInteractions,
} from "~/common/utils/types/checkInTypes";
import {
  AllSetCard,
  CheckInCard,
  SubmitStatus,
} from "~/patients/components/home";
import { useGetPatientCheckIns } from "~/patients/hooks";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { ROUTES } from "~/patients/router";
import { useNavMenuStore } from "~/patients/stores/useNavMenuStore";

export const ToDo = () => {
  const { data: user, isFetching: isUserFetching } = useGetCurrentPatient();

  const { totalToDoCheckIns, setTotalToDoCheckIns } = useNavMenuStore();

  const { data: checkins, isLoading: isLoadingToDoCheckIns } =
    useGetPatientCheckIns(
      true,
      !isUserFetching && !!user && isPatientActive(user?.status),
      CHECKIN_STATUS.pending,
      CreatedByProviderInteractions,
    );

  const { data: patientCheckIns, total } = { ...checkins };

  useEffect(() => {
    if (totalToDoCheckIns !== total) setTotalToDoCheckIns(total ?? 0);
  }, [total]);

  const getDesktopGrid = (length: number) => {
    if (length >= 3) return "md:grid-cols-3";
    if (length === 2) return "md:grid-cols-2";
  };

  if (isLoadingToDoCheckIns || isUserFetching) return <LoadingState />;

  const { preferred_reference: providerName } = { ...user?.provider };

  if (!user) return null;

  if (!isPatientActive(user?.status))
    return <Navigate to={ROUTES.leadPatientHome} />;

  return (
    <div className="flex flex-col gap-6 divide-y-2 p-6 lg:px-32 lg:py-8">
      {!!patientCheckIns?.length && (
        <div className="flex flex-col gap-4 md:items-center">
          <p className="text-center text-base text-gray-10">
            {providerName} is looking forward to your next update!
          </p>
          <div
            className={twMerge(
              "grid grid-cols-1 gap-4",
              getDesktopGrid(patientCheckIns?.length),
            )}
          >
            {!!patientCheckIns &&
              patientCheckIns.map((patientCheckIn) => (
                <CheckInCard
                  key={patientCheckIn.id}
                  patientCheckIn={patientCheckIn}
                />
              ))}
          </div>
        </div>
      )}
      {!patientCheckIns?.length && <AllSetCard />}
      <SubmitStatus />
    </div>
  );
};
