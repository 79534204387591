import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { answerIntakeQuestion } from "~/patients/api";

export const useAnswerIntakeQuestion = () => {
  const queryClient = useQueryClient();

  const { mutate: answerQuestionMutation, isPending } = useMutation({
    mutationFn: answerIntakeQuestion,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getCurrentIntakeQuestion"],
      });
    },
    onError: () =>
      toast.error(
        "An error occurred while submitting your answer. Please try again.",
      ),
  });

  return { answerQuestionMutation, isPending };
};
