import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { answerQuestion } from "~/patients/api/consultations/screenings";

export const useAnswerQuestion = () => {
  const queryClient = useQueryClient();

  const { mutate: answerQuestionMutation, isPending } = useMutation({
    mutationFn: answerQuestion,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getCurrentScreeningQuestion"],
      });
    },
    onError: () =>
      toast.error(
        "An error occurred while submitting your answer. Please try again.",
      ),
  });

  return { answerQuestionMutation, isPending };
};
