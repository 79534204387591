import { useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import {
  ScreeningQuestionAnswer,
  screeningQuestionDataByCodes,
  screeningSectionTitle,
  TOTAL_AMOUNT_OF_SCREENINGS,
} from "~/common/utils/consultations/screening";
import {
  ConsultationFormLayout,
  ScreeningCompletedScreen,
  ScreeningQuestionRenderer,
} from "~/patients/components/consultations";
import {
  useAnswerQuestion,
  useGetCurrentPatient,
  useGetCurrentScreeningQuestion,
  useGoBackOneQuestion,
} from "~/patients/hooks/queries";

export const ScreeningQuestion = () => {
  const { id } = useParams();

  const { data: patient } = useGetCurrentPatient();
  const consultationId = patient?.consultation?.id;

  const { data: currentQuestion, isLoading } = useGetCurrentScreeningQuestion(
    id,
    consultationId,
  );
  const { goBackOneQuestionMutation } = useGoBackOneQuestion();
  const { answerQuestionMutation, isPending } = useAnswerQuestion();

  if (!id) return null;

  const handleGoBackFinalScreen = () => {
    goBackOneQuestionMutation({ id, consultationId });
  };

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  if (!currentQuestion)
    return (
      <ScreeningCompletedScreen
        handleGoBackFinalScreen={handleGoBackFinalScreen}
      />
    );

  const {
    id: questionId,
    code,
    form: screening,
    answer,
    optional,
  } = currentQuestion;
  const question = screeningQuestionDataByCodes[code];
  const title = screeningSectionTitle[screening.code];
  const isFirstQuestion = questionId === screening.first_question_id;

  const onSubmit = (answer: ScreeningQuestionAnswer) =>
    answerQuestionMutation({ id, consultationId, answer });

  return (
    <ConsultationFormLayout
      currentSection={screening.order}
      title={title}
      totalSteps={TOTAL_AMOUNT_OF_SCREENINGS}
    >
      <ScreeningQuestionRenderer
        key={currentQuestion.id}
        question={question}
        handleGoBack={() => goBackOneQuestionMutation({ id, consultationId })}
        isOptional={optional}
        onSubmit={onSubmit}
        disableBack={isFirstQuestion}
        answer={answer}
        isSubmitting={isPending}
      />
    </ConsultationFormLayout>
  );
};
