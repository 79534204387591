import { useQuery } from "@tanstack/react-query";

import { intakeAvailable } from "~/common/utils/consultations/intake";
import { getCurrentIntakeBundle } from "~/patients/api";
import { useGetCurrentPatient } from "~/patients/hooks";

export const useGetCurrentIntakeBundle = () => {
  const { data: user } = useGetCurrentPatient();
  const { id: consultationId, status } = { ...user?.consultation };

  const { data, isLoading, isFetching } = useQuery({
    queryFn: () => getCurrentIntakeBundle(consultationId),
    queryKey: ["getCurrentIntakeBundle", user, consultationId],
    enabled: !!user && intakeAvailable(status),
  });

  return { data, isLoading, isFetching };
};
