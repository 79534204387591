import { z } from "zod";

import { createGenericStringSchema } from "~/common/utils";
import { replaceUnderscores } from "~/common/utils/replaceUnderscores";
import {
  ADMISSION_RECOMMENDATION_VALUES,
  LARGE_TEXT_LENGTH,
  REFER_PATIENT,
  REFER_PATIENT_PROVIDED_VALUES,
  REFER_PATIENT_VALUES,
  TEXT_MAX_LENGTH,
} from "~/providers/constants";
import {
  affectQuestionOptions,
  appearanceQuestionOptions,
  attentionAndConcentrationQuestionOptions,
  behaviorQuestionOptions,
  createMultiCheckSchemaMentalStatusExaminations,
  judgmentAndInsightQuestionOptions,
  MENTAL_STATUS_EXAMINATIONS_QUESTIONS,
  MENTAL_STATUS_EXAMINATIONS_VALUES,
  MentalStatusExaminationsQuestions,
  moodQuestionOptions,
  speechQuestionOptions,
  thoughtContentQuestionOptions,
  thoughtProcessQuestionOptions,
} from "~/providers/utils/mentalStatusExaminations";

export const PRICE_TYPES = {
  default: "default",
  custom: "custom",
} as const;

const defaultFeeSchema = z.object({
  priceType: z.literal(PRICE_TYPES.default),
});

const customFeeSchema = z.object({
  priceType: z.literal(PRICE_TYPES.custom),
  price: z.string().regex(/^\d+(\.\d{1,2})?$/, "Price must be a valid number"),
});

export const consultationDetailSchema = z.discriminatedUnion("priceType", [
  customFeeSchema,
  defaultFeeSchema,
]);

export type ConsultationDetailType = z.infer<typeof consultationDetailSchema>;

const buildMentalStatusExaminationSchema = (required: boolean) =>
  z.object({
    appearance: createMultiCheckSchemaMentalStatusExaminations(
      appearanceQuestionOptions,
      required,
    ),
    behavior: createMultiCheckSchemaMentalStatusExaminations(
      behaviorQuestionOptions,
      required,
    ),
    mood: createMultiCheckSchemaMentalStatusExaminations(
      moodQuestionOptions,
      required,
    ),
    speech: createMultiCheckSchemaMentalStatusExaminations(
      speechQuestionOptions,
      required,
    ),
    affect: createMultiCheckSchemaMentalStatusExaminations(
      affectQuestionOptions,
      required,
    ),
    thought_process: createMultiCheckSchemaMentalStatusExaminations(
      thoughtProcessQuestionOptions,
      required,
    ),
    thought_content: createMultiCheckSchemaMentalStatusExaminations(
      thoughtContentQuestionOptions,
      required,
    ),
    attention_and_concentration: createMultiCheckSchemaMentalStatusExaminations(
      attentionAndConcentrationQuestionOptions,
      required,
    ),
    judgment_and_insight: createMultiCheckSchemaMentalStatusExaminations(
      judgmentAndInsightQuestionOptions,
      required,
    ),
  });

export const mentalStatusExaminationSchema =
  buildMentalStatusExaminationSchema(false);

export const mentalStatusExaminationSchemaRequired =
  buildMentalStatusExaminationSchema(true);

export const assessmentAndPlanSchema = mentalStatusExaminationSchemaRequired
  .merge(
    z.object({
      diagnostic_considerations: z
        .string()
        .min(1, "Please enter a diagnostic.")
        .max(
          LARGE_TEXT_LENGTH,
          `Please limit diagnostic considerations to ${LARGE_TEXT_LENGTH} characters.`,
        ),
      treatment_plan: z
        .string()
        .min(1, "Please enter treatment plan.")
        .max(
          LARGE_TEXT_LENGTH,
          `Please limit treatment plan to ${LARGE_TEXT_LENGTH} characters.`,
        ),
      admission_recommended: z.enum(ADMISSION_RECOMMENDATION_VALUES, {
        message: "Please select an option.",
      }),
      refer_patient: z.enum(REFER_PATIENT_VALUES, {
        message: "Please select an option.",
      }),
      refer_note: z
        .string()
        .max(TEXT_MAX_LENGTH, `Max length is ${TEXT_MAX_LENGTH} characters.`)
        .optional()
        .nullable(),
    }),
  )
  .superRefine((data, ctx) => {
    if (
      data.refer_patient === REFER_PATIENT.referral_provided &&
      (!data.refer_note || data.refer_note.length === 0)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["refer_note"],
        message: "Please enter a referral note.",
      });
    }

    Object.keys(MENTAL_STATUS_EXAMINATIONS_QUESTIONS).forEach((key) => {
      const field = data[key as MentalStatusExaminationsQuestions];
      if (!field) return;
      if (!field?.values) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["data", key, "values"],
          message: `Please select a value for ${replaceUnderscores(key)}.`,
        });
      }

      const otherValue = field.values.find(
        (option) => option.value === MENTAL_STATUS_EXAMINATIONS_VALUES.other,
      );
      if (otherValue?.extra_data === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [key, "extra_data"],
          message: `Please provide additional information for ${replaceUnderscores(
            key,
          )}.`,
        });
      }
    });
  });

export type AssessmentAndPlanFormValuesType = z.infer<
  typeof assessmentAndPlanSchema
>;

export const saveConsultationCompletionSchema =
  mentalStatusExaminationSchema.merge(
    z.object({
      diagnostic_considerations: z
        .string()
        .max(
          LARGE_TEXT_LENGTH,
          `Please limit diagnostic considerations to ${LARGE_TEXT_LENGTH} characters.`,
        )
        .optional()
        .nullable(),
      treatment_plan: z
        .string()
        .max(
          LARGE_TEXT_LENGTH,
          `Please limit treatment plan to ${LARGE_TEXT_LENGTH} characters.`,
        )
        .optional()
        .nullable(),
      admission_recommended: z
        .enum(ADMISSION_RECOMMENDATION_VALUES)
        .optional()
        .nullable(),
      provider_care_plan_id: z.string().optional().nullable(),
      refer_patient: z.enum(REFER_PATIENT_VALUES).optional().nullable(),
      refer_note: z
        .string()
        .max(
          TEXT_MAX_LENGTH,
          `Please limit referral note to ${TEXT_MAX_LENGTH} characters.`,
        )
        .optional()
        .nullable(),
    }),
  );

export type SaveConsultationCompletionFormValuesType = z.infer<
  typeof saveConsultationCompletionSchema
>;

export const consultationCompletionDataSchema = z.discriminatedUnion(
  "completeAndSign",
  [
    z.object({
      completeAndSign: z.literal(false),
      data: saveConsultationCompletionSchema,
    }),
    z.object({
      completeAndSign: z.literal(true),
      data: assessmentAndPlanSchema,
    }),
  ],
);

export type ConsultationCompletionDataType = z.infer<
  typeof consultationCompletionDataSchema
>;

export const internalNoteSchema = z.object({
  internal_note: createGenericStringSchema({
    inputName: "Internal note",
    required: true,
    maxLength: TEXT_MAX_LENGTH,
  }),
});

export type InternalNoteType = z.infer<typeof internalNoteSchema>;

export const optionalInternalNoteSchema = z.object({
  internal_note: createGenericStringSchema({
    inputName: "Internal note",
    required: false,
    maxLength: TEXT_MAX_LENGTH,
  }),
});

export type OptionalInternalNoteType = z.infer<
  typeof optionalInternalNoteSchema
>;

export const closeAfterScreeningSchema = internalNoteSchema.extend({
  referral: z.enum(REFER_PATIENT_PROVIDED_VALUES, {
    message: "Please select whether a referral was provided",
  }),
});

export type CloseAfterScreeningType = z.infer<typeof closeAfterScreeningSchema>;
