import { z } from "zod";

import { TEXT_MAX_LENGTH } from "~/common/constants";
import { createGenericStringSchema } from "~/common/utils";

export const carePlanOptionsSchema = z.object({
  carePlanId: z.string().min(1, "Please select a plan to continue"),
});

export type CarePlanOptionsSchemaValues = z.infer<typeof carePlanOptionsSchema>;

export const admitPatientSchema = z.object({
  provider_care_plan_id: z.string().min(1, "Please select a plan to continue"),
  internal_note: createGenericStringSchema({
    inputName: "Internal note",
    required: false,
    maxLength: TEXT_MAX_LENGTH,
  }),
});
export type AdmitPatientFormValues = z.infer<typeof admitPatientSchema>;
