import React, { ComponentPropsWithoutRef, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface QuestionaryCheckBoxProps extends ComponentPropsWithoutRef<"input"> {
  id: string;
  children: ReactNode;
  labelClassName?: string;
  className?: string;
}

const QuestionaryCheckBox = React.forwardRef<
  HTMLInputElement,
  QuestionaryCheckBoxProps
>(
  (
    {
      className,
      children,
      id,
      labelClassName,
      ...props
    }: QuestionaryCheckBoxProps,
    ref,
  ) => {
    return (
      <label
        htmlFor={id}
        className={twMerge(
          "flex w-full items-center justify-between gap-2 rounded-md border border-neutral-700 bg-white p-3",
          props.checked && "border-primary-500 bg-primary-200",
          props.checked && props.disabled && "border-gray-700 bg-gray-200",
          labelClassName,
        )}
      >
        <span className="first-letter:capitalize">{children}</span>
        <input
          className={twMerge(
            "size-5 cursor-pointer rounded-md border-2 border-neutral-700 bg-slate-50",
            "checked:border-transparent checked:bg-primary-500 checked:hover:bg-primary-600",
            "focus:outline-none focus:ring-transparent focus:checked:border-transparent focus:checked:bg-primary-500",
            "disabled:cursor-default disabled:bg-gray-300 disabled:hover:bg-gray-300",
            className,
          )}
          id={id}
          {...props}
          ref={ref}
          type="checkbox"
          disabled={props.disabled}
        />
      </label>
    );
  },
);

QuestionaryCheckBox.displayName = "QuestionaryCheckBox";
export default QuestionaryCheckBox;
