import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { WaveBackgroundLayout } from "~/common/components";
import { useOnBoardingPatientStore } from "~/patients/stores/useOnBoardingPatientStore";
import {
  ActivePatientSchemaType,
  PATIENTS_TYPES,
  signUpPatientSchema,
  SignUpPatientSchemaType,
} from "~/patients/utils/PatientSchemas";
import { ActivePatientSignUpRenderer } from "../components/signUp/signUpActivePatient/ActivePatientSignUpRenderer";
import { useValidateSignupInfo } from "../hooks/signUp";
import { useSignUpActivePatient } from "../hooks/signUp/activePatient/useSignUpActivePatient";
import { SignUpTokenData } from "../utils";

export const SignUpActivePatient = () => {
  const { patient } = useOnBoardingPatientStore();
  const [signUpTokenData, setSignUpTokenData] = useState<SignUpTokenData>();

  const {
    formState: { errors },
    register,
    control,
    getValues,
    watch,
    trigger,
    resetField,
  } = useForm<SignUpPatientSchemaType>({
    defaultValues: {
      ...patient,
      type: PATIENTS_TYPES.activePatient,
    },
    resolver: zodResolver(signUpPatientSchema),
    mode: "onBlur",
  });

  const values = getValues() as ActivePatientSchemaType;

  const { onNext, onBack, isPending, onSubmitSignUp } = useSignUpActivePatient({
    values,
    trigger,
    signUpTokenData,
  });

  const { validateSignUpActivePatientToken } = useValidateSignupInfo();

  useEffect(() => {
    validateSignUpActivePatientToken({ setSignUpTokenData });
  }, [validateSignUpActivePatientToken]);

  if (!signUpTokenData) return null;

  return (
    <WaveBackgroundLayout>
      <ActivePatientSignUpRenderer
        onNext={onNext}
        handleRegistration={onSubmitSignUp}
        handleGoBack={onBack}
        register={register}
        resetField={resetField}
        errors={errors}
        control={control}
        watch={watch}
        isPending={isPending}
        signUpTokenData={signUpTokenData}
      />
    </WaveBackgroundLayout>
  );
};
