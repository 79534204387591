import { useQuery } from "@tanstack/react-query";

import { getProviderByInvitationCode } from "~/providers/api";

export const useGetProviderByInvitationCode = (
  invitationCode: string | null,
) => {
  const { data, isFetching } = useQuery({
    queryFn: () => getProviderByInvitationCode(invitationCode ?? ""),
    queryKey: ["getProviderByInvitationCode", invitationCode],
    retry: 1,
    enabled: Boolean(invitationCode),
  });
  return { data, isFetching };
};
