import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { LoadingState, WaveBackgroundLayout } from "~/common/components";
import { NewPatientSignUpRenderer } from "../components/signUp/signUpNewPatient/NewPatientSignUpRenderer";
import { useValidateSignupInfo } from "../hooks/signUp";
import { useSignUpNewPatient } from "../hooks/signUp/useSignUpNewPatient";
import { useOnBoardingNewPatientStore } from "../stores/useOnBoardingNewPatientStore";
import {
  NewPatientSchemaType,
  PATIENTS_TYPES,
  signUpPatientSchema,
  SignUpPatientSchemaType,
} from "../utils";

export const SignUpNewPatient = () => {
  const { newPatient } = useOnBoardingNewPatientStore();
  const [providerId, setProviderId] = useState<string>();
  const [isFetchingValidation, setIsFetchingValidation] = useState<boolean>();

  const {
    formState: { errors },
    register,
    control,
    getValues,
    watch,
    trigger,
  } = useForm<SignUpPatientSchemaType>({
    defaultValues: {
      ...newPatient,
      type: PATIENTS_TYPES.newPatient,
    },
    resolver: zodResolver(signUpPatientSchema),
    mode: "onBlur",
  });

  const values = getValues() as NewPatientSchemaType;

  const { onNext, onBack, isPending, onSubmitSignUp } = useSignUpNewPatient({
    values,
    trigger,
    providerId,
  });

  const { validateSignUpNewPatientToken } = useValidateSignupInfo();

  useEffect(() => {
    const { isFetching, providerId } = validateSignUpNewPatientToken();

    setIsFetchingValidation(isFetching);
    setProviderId(providerId);
  }, [validateSignUpNewPatientToken]);

  if (!providerId || isFetchingValidation)
    return <LoadingState className="h-screen" />;

  return (
    <WaveBackgroundLayout>
      <NewPatientSignUpRenderer
        onNext={onNext}
        handleRegistration={onSubmitSignUp}
        handleGoBack={onBack}
        register={register}
        errors={errors}
        control={control}
        watch={watch}
        isPending={isPending}
      />
    </WaveBackgroundLayout>
  );
};
