import axios, { AxiosInstance } from "axios";

import {
  AuthenticationResponse,
  InterfaceWithId,
  TeamMember,
} from "~/common/utils/types";
import { env } from "../env";
import { api } from "./axios";

interface ProviderLogInRequest {
  email: string;
  password: string;
}

export interface UpdateTeamMemberData {
  signature_name?: string;
  preferred_reference?: string;
  acuity_client_id?: string;
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: env.VITE_API_URL,
  withCredentials: true,
});

export const getCurrentTeamMember = async () => {
  const { data } = await api.get<TeamMember>("/v1/team-members/current");
  return data;
};

export const getTeamMemberIsAuth = async () => {
  const { data } = await api.get<boolean>("/v1/auth/team-members");
  return data;
};

export const login = async (data: ProviderLogInRequest) => {
  const response = await axiosInstance.post<AuthenticationResponse>(
    "v1/team-members/login",
    {
      ...data,
    },
  );
  return response.data;
};

export const resetPassword = async (newPassword: string, token: string) => {
  const response = await api.post<string>(
    "v1/auth/reset-password",
    { password: newPassword },
    {
      headers: {
        token: token,
      },
    },
  );
  return response.data;
};

export const requestEmail = async (email: string) => {
  const response = await api.post<string>(
    "/v1/auth/email/provider/reset-password",
    {
      email,
    },
  );
  return response.data;
};

export const getTeamMembersList = async () => {
  const { data } = await api.get<TeamMember[]>("/v1/team-members/staff");
  return data;
};

export const updateTeamMemberData = async (
  teamMemberData: UpdateTeamMemberData,
) => {
  const { data } = await api.patch<TeamMember>(
    "v1/team-members",
    teamMemberData,
  );

  return data;
};

export const logoutTeamMember = async () => {
  const { data } = await api.post<string>("v1/team-members/logout");
  return data;
};

export const validateProviderId = async (providerId: string) => {
  const { data } = await api.get<null>(
    `/v1/patients/providers/${providerId}/validate`,
  );
  return data;
};

export const getProviderByInvitationCode = async (invitationCode: string) => {
  const { data } = await api.get<InterfaceWithId>(`/v1/patients/providers`, {
    params: { invitation_code: invitationCode },
  });
  return data;
};
