import {
  PATIENT_CONSULTATION_STATUS,
  PatientConsultationStatus,
} from "~/common/constants/consultation";

export const getConsultationText = (
  consultationStatus: PatientConsultationStatus,
  providerName: string,
) => {
  if (consultationStatus === PATIENT_CONSULTATION_STATUS.screening_submitted)
    return `${providerName} is reviewing your request and will be in touch with you very soon.`;

  if (consultationStatus === PATIENT_CONSULTATION_STATUS.closed_after_screening)
    return "Your consultation has ended. Please contact our team if you have any questions.";

  return "Your input is essential to your ongoing wellness!";
};
