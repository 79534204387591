import { useQuery } from "@tanstack/react-query";

import { intakeAvailable } from "~/common/utils/consultations/intake";
import { getCurrentIntakeQuestion } from "~/patients/api";
import { useGetCurrentPatient } from "~/patients/hooks";

export const useGetCurrentIntakeQuestion = (
  patientIntakeId?: string,
  enabled = true,
) => {
  const { data: user } = useGetCurrentPatient();
  const consultationId = user?.consultation?.id;

  const enableQuery =
    intakeAvailable(user?.consultation?.status) && !!patientIntakeId && enabled;

  const { data, isLoading, isFetching } = useQuery({
    queryFn: () =>
      getCurrentIntakeQuestion(patientIntakeId ?? "", consultationId),
    queryKey: [
      "getCurrentIntakeQuestion",
      user,
      patientIntakeId,
      consultationId,
    ],
    enabled: enableQuery,
  });

  return { data, isLoading, isFetching };
};
