import { PATIENT_CONSULTATION_STATUS } from "~/common/constants/consultation";
import { PatientIntakeBundle } from "~/common/utils/consultations/intake/interfaces";
import {
  AppointmentCompletedCard,
  AppointmentScheduledCard,
  CompletedConsultationCard,
  IntakeSubmittedCard,
  ScreeningCompletedAndIntakeStartedCard,
} from "~/patients/components";
import { useGetCurrentPatient } from "~/patients/hooks";

interface ConsultationCardProps {
  title: string;
  intakeData: PatientIntakeBundle;
}

export const AppointmentConsultationCard = ({
  title,
  intakeData,
}: ConsultationCardProps) => {
  const { data: user } = useGetCurrentPatient();
  const { provider, consultation } = { ...user };

  const patientFilledIntake =
    intakeData.sections_completed === intakeData.total_sections;

  const renderCard = () => {
    switch (consultation?.status) {
      case PATIENT_CONSULTATION_STATUS.intake_not_started:
      case PATIENT_CONSULTATION_STATUS.intake_started:
        return patientFilledIntake ? (
          <IntakeSubmittedCard title={title} />
        ) : (
          <ScreeningCompletedAndIntakeStartedCard title={title} />
        );

      case PATIENT_CONSULTATION_STATUS.intake_submitted:
        return <IntakeSubmittedCard title={title} />;

      case PATIENT_CONSULTATION_STATUS.screening_started:
        return <AppointmentScheduledCard title={title} />;

      case PATIENT_CONSULTATION_STATUS.appointment_scheduled:
        return <CompletedConsultationCard title={title} />;

      case PATIENT_CONSULTATION_STATUS.completed:
        return provider ? (
          <AppointmentCompletedCard title={title} provider={provider} />
        ) : null;

      default:
        return null;
    }
  };

  return <>{renderCard()}</>;
};
