import { Navigate } from "react-router-dom";

import { isPatientActive } from "~/common/utils";
import { IntakeQuestion } from "~/patients/components";
import { useGetCurrentPatient } from "~/patients/hooks";
import { ROUTES } from "~/patients/router";

export const Intake = () => {
  const { data: user } = useGetCurrentPatient();

  if (isPatientActive(user?.status)) return <Navigate to={ROUTES.notFound} />;

  return <IntakeQuestion />;
};
