import { generatePath, useNavigate } from "react-router-dom";

import { CONSULTATION_STATUSES } from "~/common/utils";
import { useGetCurrentPatient, useGetIntake } from "~/patients/hooks/queries";
import { ROUTES } from "~/patients/router";
import {
  isCompletedConsultation,
  isNextConsultation,
  isUnavailableConsultation,
} from "~/patients/utils";

export const useIntakeItem = (id: string, status: string) => {
  const isInProgress = status === CONSULTATION_STATUSES.in_progress;
  const { data: patient } = useGetCurrentPatient();
  const consultationId = patient?.consultation?.id;

  const { data: completeIntakeInformation } = useGetIntake(
    id,
    consultationId,
    isInProgress,
  );

  const { patient_intake_id: patientIntakeId } = {
    ...completeIntakeInformation,
  };

  const navigate = useNavigate();
  const handleComplete = () => {
    if (isInProgress && patientIntakeId) {
      navigate(generatePath(ROUTES.intake, { id: patientIntakeId }));
    } else {
      navigate(generatePath(ROUTES.startIntake, { id }));
    }
  };

  const isUnavailable = isUnavailableConsultation(status);
  const isCompleted = isCompletedConsultation(status);
  const isPending = isNextConsultation(status);

  return {
    handleComplete,
    isInProgress,
    isUnavailable,
    isCompleted,
    isPending,
  };
};
