export const BASE_SETTINGS_PATH = "/settings";
export const BASE_PATIENT_PATH = "/patients";
export const BASE_PATIENT_PROFILE_PATH = `${BASE_PATIENT_PATH}/:patientId`;
export const BASE_LEAD_PATIENT_PROFILE_PATH = `/consultations${BASE_PATIENT_PATH}/:patientId`;
export const CONSULTATION_PATH = "/consultation";
export const ONGOING_CONSULTATION_PATH = `${BASE_LEAD_PATIENT_PROFILE_PATH}${CONSULTATION_PATH}`;
export const PATIENT_CONSULTATION_PATH = `${BASE_PATIENT_PROFILE_PATH}${CONSULTATION_PATH}`;
export const CONSULTATION_REPORT_PATH = `${BASE_PATIENT_PROFILE_PATH}/consultation-report`;

export const ROUTES = {
  login: "/login",
  signUp: "/sign-up",
  home: "/",
  forgotPassword: "/forgot-password",
  resetPassword: "/password-reset",
  activePatientActivityFeed: `${BASE_PATIENT_PROFILE_PATH}/activity-feed`,
  leadPatientActivityFeed: `${BASE_LEAD_PATIENT_PROFILE_PATH}/activity-feed`,
  medication: `${BASE_PATIENT_PROFILE_PATH}/medications`,
  ouraRing: `${BASE_PATIENT_PROFILE_PATH}/oura-ring`,
  orders: `${BASE_PATIENT_PROFILE_PATH}/orders`,
  notFound: "/not-found",
  internalError: "/internal-error",
  prescriptionRequest: "/prescription-requests",
  consultationRequests: "/consultations",
  account: `${BASE_SETTINGS_PATH}/account`,
  payment: `${BASE_SETTINGS_PATH}/payment`,
  carePlans: `${BASE_SETTINGS_PATH}/care-plans`,
  patientConsultationInfo: `${BASE_LEAD_PATIENT_PROFILE_PATH}/info`,
  patientInfo: `${BASE_PATIENT_PROFILE_PATH}/info`,
  consultationPreview: `${ONGOING_CONSULTATION_PATH}/preview`,
  consultationResponses: `${ONGOING_CONSULTATION_PATH}/responses`,
  consultationComplete: `${ONGOING_CONSULTATION_PATH}/complete`,
  patientPreview: `${PATIENT_CONSULTATION_PATH}/preview`,
  patientResponses: `${PATIENT_CONSULTATION_PATH}/responses`,
  patientComplete: `${PATIENT_CONSULTATION_PATH}/complete`,
  patientSearch: "/patient-search",
  leadPatientConsultationReport: `${ONGOING_CONSULTATION_PATH}/consultation-report`,
  ongoingPatientConsultationReport: `${PATIENT_CONSULTATION_PATH}/consultation-report`,
  exportConsultationReport: `${PATIENT_CONSULTATION_PATH}/consultation-report/export`,
} as const;
