import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { generatePath, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

import { ErrorText, TransitionEffect } from "~/common/components";
import { YES_NO_NA_TYPES } from "~/common/utils/consultations/intake";
import {
  CompletedYesNoNAWithAudioAnswer,
  YesNoNAWithAudioOnYesAnswer,
  YesNoNAWithAudioOnYesQuestionData,
} from "~/common/utils/consultations/intake/interfaces";
import {
  AudioRecorder,
  ConsultationQuestionsLayout,
} from "~/patients/components";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import {
  useDeleteConsultationAudioS3,
  useGetCurrentPatient,
  useUploadConsultationAudioS3,
} from "~/patients/hooks";
import { useHandleExtraDataAudio } from "~/patients/hooks/consultations/useHandleExtraDataAudio";
import { yesNoNaAudioSchema } from "~/patients/utils/consultations/intakes";
import { getConsultationBaseUrl } from "~/providers/utils";

export interface RadioFormProps {
  question: YesNoNAWithAudioOnYesQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: YesNoNAWithAudioOnYesAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer?: CompletedYesNoNAWithAudioAnswer;
}

export const RadioYesNoNaAudioForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  disableBack,
  completedAnswer,
}: RadioFormProps) => {
  const { options, title, supertitle, subtitle } = question;

  type FormValues = z.infer<typeof yesNoNaAudioSchema>;
  const { id: patientIntakeId } = useParams();
  const { data: patient } = useGetCurrentPatient();
  const consultationId = patient?.consultation?.id;

  const basePath = generatePath(
    `${getConsultationBaseUrl(consultationId)}/intakes/:patientIntakeId`,
    {
      patientIntakeId: patientIntakeId ?? "",
    },
  );

  const { handleUploadAudio, pendingUploadAudio } =
    useUploadConsultationAudioS3(basePath);
  const { deleteAudioMutation, pendingDeleteAudio } =
    useDeleteConsultationAudioS3(basePath);

  const completedAudioDuration =
    completedAnswer?.value === YES_NO_NA_TYPES.yes &&
    completedAnswer?.audio?.duration
      ? parseInt(completedAnswer?.audio.duration)
      : undefined;

  const defaultValues =
    completedAnswer?.value === YES_NO_NA_TYPES.yes
      ? {
          answer: {
            value: YES_NO_NA_TYPES.yes,
            audio: {
              duration: completedAudioDuration,
            },
          },
        }
      : { answer: { value: completedAnswer?.value } };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: defaultValues,
    resolver: zodResolver(yesNoNaAudioSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const optionSelected = watch("answer.value");
  const audioRequired = optionSelected === YES_NO_NA_TYPES.yes;

  const {
    audioFile,
    audioRecorderRef,
    deleteRecordingAndGoBack,
    setRecordedAudio,
    handleDeleteAudio,
    userRecording,
    handleSuddenStopRecording,
  } = useHandleExtraDataAudio(
    handleGoBack,
    setValue,
    reset,
    deleteAudioMutation,
    clearErrors,
    completedAnswer?.presigned_url,
  );

  const saveAnswers: SubmitHandler<FormValues> = async (data) => {
    if (audioFile) {
      try {
        await handleUploadAudio(audioFile);
      } catch (error) {
        toast.error(
          "There was an error saving your audio. If the issue persists, please reload the page.",
        );
      }
    }

    let answer = data;
    if (
      completedAnswer?.presigned_url &&
      data.answer.value !== YES_NO_NA_TYPES.yes
    ) {
      await deleteAudioMutation();
      answer = { answer: { value: data.answer.value } };
    }
    onSubmit(answer);
  };

  useEffect(() => {
    if (optionSelected === YES_NO_NA_TYPES.yes) return;
    handleSuddenStopRecording();
    setValue("answer.value", optionSelected);
  }, [optionSelected]);

  const audioError = errors.answer?.audio?.duration?.message;
  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={deleteRecordingAndGoBack}
      disableNext={!watch("answer.value") || userRecording}
      errors={errors.answer?.message}
      isSubmitting={isSubmitting || pendingUploadAudio}
      title={title}
      subtitle={subtitle}
      supertitle={supertitle}
      disableBack={disableBack}
    >
      {options.map(({ value, label }) => (
        <RadioButtonOption
          key={value}
          label={label}
          value={value}
          isSelected={optionSelected === value}
          {...register("answer.value")}
        />
      ))}
      <TransitionEffect
        show={audioRequired}
        className="div flex w-full flex-col gap-2"
      >
        <AudioRecorder
          ref={audioRecorderRef}
          setAudio={setRecordedAudio}
          handleDeleteAudio={handleDeleteAudio}
          pendingDeleteAudio={pendingDeleteAudio}
          defaultAudioSrc={completedAnswer?.presigned_url}
          defaultAudioDuration={completedAudioDuration}
          supertitle="Please describe further."
        />
        {audioError && <ErrorText>{audioError}</ErrorText>}
      </TransitionEffect>
    </ConsultationQuestionsLayout>
  );
};
