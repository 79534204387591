import { useQuery } from "@tanstack/react-query";

import { getIntake } from "~/patients/api";

export const useGetIntake = (
  intakeId?: string,
  consultationId?: string,
  enabled = true,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getIntake(intakeId ?? "", consultationId),
    queryKey: ["getIntake", intakeId, consultationId],
    enabled: !!intakeId && enabled,
  });

  return { data, isLoading };
};
