import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { PatientScreeningQuestionResponse } from "~/common/utils/consultations/screening";
import { getCurrentScreeningQuestion } from "~/patients/api";
import { ROUTES } from "~/patients/router";

export const useGetCurrentScreeningQuestion = (
  patientScreeningId?: string,
  consultationId?: string,
) => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery<
    PatientScreeningQuestionResponse | null,
    AxiosError
  >({
    queryFn: () =>
      getCurrentScreeningQuestion(patientScreeningId ?? "", consultationId),
    queryKey: [
      "getCurrentScreeningQuestion",
      patientScreeningId,
      consultationId,
    ],
    enabled: !!patientScreeningId,
    retry: 2,
  });

  if (error?.response?.status === 400) {
    navigate(ROUTES.screeningAlreadyCompleted);
  }

  return { data, isLoading };
};
