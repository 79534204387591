import { GroupOptions } from "~/common/utils";

export const ENGAGEMENT_TYPES = {
  active: "active",
  consultation: "consultation",
  inactive: "inactive",
} as const;

export const ENGAGEMENT_OPTIONS = Object.values(ENGAGEMENT_TYPES);

export type EngagementTypes = keyof typeof ENGAGEMENT_TYPES;

export const engagementFilterOptions: GroupOptions[] = Object.values(
  ENGAGEMENT_TYPES,
).map((option) => ({
  value: option,
  label: option,
}));
