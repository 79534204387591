import axios, { AxiosError } from "axios";

import { env } from "../env";
import { errorInterceptor } from "./interceptors/auth";

export const api = axios.create({
  baseURL: env.VITE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

api.interceptors.response.use((response) => response, errorInterceptor);

export interface Pagination {
  page_size: number;
  page: number;
  filtered_count: number;
  total_pages: number;
  total_items?: number;
}
export interface PaginatedServiceResponse<T> extends Pagination {
  status: number;
  success: boolean;
  data: T;
}
interface ErrorMessage {
  detail?: string;
  error?: string;
}
export type ErrorResponse = AxiosError<ErrorMessage>;
