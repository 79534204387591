import { env } from "~/patients/env";

export const DIRECTIONS = {
  top: "top",
  right: "right",
  left: "left",
  down: "down",
} as const;

export const USER_TYPES = {
  provider: "provider",
  staff: "staff",
  patient: "patient",
};

export const OTHER_VALUE = "other" as const;
export const OTHER_OPTION = { label: "Other", value: OTHER_VALUE } as const;
export const RECURRENT = "recurrent";

export const APPOINTMENT_DURATIONS = {
  minutes_5: "5 min",
  minutes_10: "10 min",
  minutes_15: "15 min",
  minutes_20: "20 min",
  minutes_25: "25 min",
  minutes_30: "30 min",
} as const;

export const DEFAULT_PAGE_SIZE = "20" as const;
export const SCRIPTS_PAGE_SIZE = "20" as const;
export const ORDER = { ascendant: "asc", descendant: "desc" } as const;
export const ORDER_BY = {
  creationDate: "created_at",
  completedDate: "completed_at",
  lastName: "last_name",
  genericName: "generic_name",
} as const;

export const TEAM_MEMBER_VALUES = {
  provider: "provider",
  staff: "staff",
} as const;

export const BUTTON_SIZES = {
  sm: "sm",
  md: "md",
  lg: "lg",
} as const;

export const ANIMATION_DURATIONS = {
  fast: 200,
  normal: 300,
  moderate: 400,
  slow: 500,
} as const;

export const PATIENT_STATUS = {
  consultation: "consultation",
  active: "active",
} as const;

export const AUDIO_MIN_DURATION = Number(env.VITE_AUDIO_MIN_DURATION);

export const AUDIO_MAX_DURATION = Number(env.VITE_AUDIO_MAX_DURATION);

export const TEAM_MEMBER_PERMISSIONS = {
  general_access: "general_access",
  consultation_access: "consultation_access",
} as const;
