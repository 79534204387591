import { UseFormRegister, UseFormWatch } from "react-hook-form";

import Input from "~/common/components/ui/Input";
import TextArea from "~/common/components/ui/Textarea";
import {
  ConsultationInputData,
  CONSULTATIONS_INPUTS_TYPES,
  ConsultationTextAnswer,
} from "~/common/utils/consultations/shared";
import { PhoneNumberInput } from "~/patients/components/consultations";
import { TEXT_MAX_LENGTH } from "~/patients/utils";

export interface InputRendererProps {
  input: ConsultationInputData;
  register: UseFormRegister<ConsultationTextAnswer>;
  value: string;
  error?: string;
  watch?: UseFormWatch<ConsultationTextAnswer>;
}

export const InputRenderer = ({
  input,
  register,
  value,
  error,
  watch,
}: InputRendererProps) => {
  const lookup = {
    [CONSULTATIONS_INPUTS_TYPES.text]: (
      <Input
        key={input.id}
        id={value}
        autoComplete="off"
        label={input.label}
        placeholder={input.placeholder}
        {...register(`answer.${value}`)}
        error={error}
      />
    ),
    [CONSULTATIONS_INPUTS_TYPES.text_area]: (
      <div key={input.id} className="flex flex-col items-end gap-2">
        <TextArea
          key={input.id}
          rows={5}
          label={input.label}
          placeholder={input.placeholder ?? `Enter ${input.label}`}
          id={value}
          {...register(`answer.${value}`)}
          error={error}
        />
        {watch && (
          <span className="mr-1 text-xs">
            {watch(`answer.${value}`)?.length ?? 0}/{TEXT_MAX_LENGTH}
          </span>
        )}
      </div>
    ),
    [CONSULTATIONS_INPUTS_TYPES.phone_number]: (
      <PhoneNumberInput
        key={input.id}
        input={input}
        register={register}
        error={error}
      />
    ),
  };

  return lookup[input.type];
};
