import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Styled } from "~/common/utils/types";

export const Tag = ({ children, className }: PropsWithChildren<Styled>) => (
  <div
    className={twMerge(
      "text flex w-fit items-center justify-center gap-1 rounded-3xl border-none px-2.5 py-0.5 text-sm",
      className,
    )}
  >
    {children}
  </div>
);
