import {
  AnswerQuestionProps,
  CompletePatientScreeningResponse,
  PatientScreeningBundle,
  PatientScreeningQuestionResponse,
  ScreeningDataResponse,
  ScreeningIntakeMutationProps,
  StartPatientScreeningResponse,
} from "~/common/utils/consultations/screening";
import { api } from "~/patients/api";
import {
  getConsultationBaseUrl,
  getPatientScreeningPath,
} from "~/providers/utils";

export const getCurrentScreeningQuestion = async (
  patientScreeningId: string,
  consultationId?: string,
) => {
  const { data } = await api.get<PatientScreeningQuestionResponse | null>(
    `${getPatientScreeningPath(patientScreeningId, consultationId)}/current`,
  );

  return data;
};

export const getScreening = async (
  screeningId: string,
  consultationId?: string,
) => {
  const { data } = await api.get<ScreeningDataResponse>(
    `${getPatientScreeningPath(screeningId, consultationId)}`,
  );

  return data;
};

export const goBackOneQuestion = async ({
  id: patientScreeningId,
  consultationId,
}: ScreeningIntakeMutationProps) => {
  const { data } = await api.post<null>(
    `${getPatientScreeningPath(patientScreeningId, consultationId)}/previous`,
    {},
  );

  return { data };
};

export const answerQuestion = async ({
  answer,
  id: patientScreeningId,
  consultationId,
}: AnswerQuestionProps) => {
  const { data } = await api.post<PatientScreeningQuestionResponse | null>(
    `${getPatientScreeningPath(patientScreeningId, consultationId)}/answers`,
    answer,
  );

  return { data };
};

export const completeScreening = async ({
  id: patientScreeningId,
  consultationId,
}: ScreeningIntakeMutationProps) => {
  const { data } = await api.post<CompletePatientScreeningResponse | null>(
    `${getPatientScreeningPath(patientScreeningId, consultationId)}/complete`,
    {},
  );

  return data;
};

export const startScreening = async ({
  id: screeningId,
  consultationId,
}: ScreeningIntakeMutationProps) => {
  const { data } = await api.post<StartPatientScreeningResponse>(
    `${getPatientScreeningPath(screeningId, consultationId)}/start`,
    {},
  );

  return data;
};

export const screeningIntroScreenVisit = async () => {
  const response = await api.patch<void>(
    "/v1/patients/screening-intro-screen/visit",
    null,
  );
  return response.data;
};

export const getCurrentScreeningBundle = async (consultationId?: string) => {
  const { data } = await api.get<PatientScreeningBundle>(
    `${getConsultationBaseUrl(consultationId)}/screenings/current/bundles`,
  );
  return data;
};
