import { useQuery } from "@tanstack/react-query";

import { getScreening } from "~/patients/api";

export const useGetScreening = (
  screeningId?: string,
  consultationId?: string,
  enabled = true,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getScreening(screeningId ?? "", consultationId),
    queryKey: ["getScreening", screeningId, consultationId],
    enabled: !!screeningId && enabled,
  });

  return { data, isLoading };
};
