import { Navigate } from "react-router-dom";

import { LoadingState, SectionHeader } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { ROUTES } from "~/patients/router";
import { CurrentMedications } from "./CurrentMedications";
import { PastMedications } from "./PastMedications";

export const Medications = () => {
  const { data: user, isLoading: isUserLoading } = useGetCurrentPatient();

  if (isUserLoading) return <LoadingState />;

  if (user?.status !== PATIENT_STATUS.active)
    return <Navigate to={ROUTES.notFound} />;

  return (
    <div className="flex w-full flex-col gap-6 text-sm">
      <div className="flex h-full flex-col gap-3">
        <SectionHeader>Current medications</SectionHeader>
        <CurrentMedications />
      </div>
      <div className="flex h-full flex-col gap-3">
        <SectionHeader>Past medications</SectionHeader>
        <PastMedications />
      </div>
    </div>
  );
};
