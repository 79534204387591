import { useEffect, useState } from "react";
import * as jose from "jose";
import { useNavigate, useSearchParams } from "react-router-dom";

import { DATA_TOKEN, INVITATION_CODE } from "~/patients/constants";
import { ROUTES } from "~/patients/router";
import { SignUpTokenData } from "~/patients/utils";
import { useValidateProviderId } from "~/providers/hooks";
import { useGetProviderByInvitationCode } from "~/providers/hooks/queries/useGetProviderByInvitationCode";

interface useValidateSignupActivePatientTokenProps {
  setSignUpTokenData: (data: SignUpTokenData) => void;
}

export const useValidateSignupInfo = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [providerId, setProviderId] = useState<string>();
  const [invitationCode, setInvitationCode] = useState<string | null>(null);

  const { isFetching: isFetchingValidateProviderId } = useValidateProviderId(
    providerId,
    !invitationCode,
  );

  const { data, isFetching: isFetchingProviderByInvitationCode } =
    useGetProviderByInvitationCode(invitationCode);

  useEffect(() => {
    setProviderId(data?.id);
  }, [data]);

  const validateSignUpNewPatientToken = () => {
    try {
      const dataTokenParam = searchParams.get(DATA_TOKEN);
      const invitationCodeParam = searchParams.get(INVITATION_CODE);

      if (dataTokenParam) {
        const userJWT = jose.decodeJwt(dataTokenParam);
        const providerId = userJWT.provider_id as string | undefined;

        if (!providerId) throw new Error("Invalid token");
        setProviderId(providerId);
      } else if (invitationCodeParam) {
        setInvitationCode(invitationCodeParam);
      } else {
        throw new Error("Not enough params");
      }
    } catch {
      navigate(ROUTES.notFound);
    }

    const isFetching =
      isFetchingValidateProviderId || isFetchingProviderByInvitationCode;
    return {
      providerId,
      isFetching,
    };
  };

  const validateSignUpActivePatientToken = ({
    setSignUpTokenData,
  }: useValidateSignupActivePatientTokenProps) => {
    try {
      const dataToken = searchParams.get(DATA_TOKEN);
      if (!dataToken) throw new Error("Missing token");
      const userJWT: SignUpTokenData = jose.decodeJwt(dataToken);

      const { user_email, user_name, provider_name, provider_image } = userJWT;

      if (!user_email || !user_name || !provider_name || !provider_image) {
        throw new Error("Invalid token");
      }

      setSignUpTokenData({ ...userJWT });
    } catch {
      navigate(ROUTES.notFound);
    }
  };

  return { validateSignUpNewPatientToken, validateSignUpActivePatientToken };
};
