import { Group, Option } from "../utils";

export const PATIENT_CONSULTATION_STATUS = {
  screening_not_started: "screening_not_started",
  intake_not_started: "intake_not_started",
  screening_started: "screening_started",
  intake_started: "intake_started",
  screening_submitted: "screening_submitted",
  intake_submitted: "intake_submitted",
  closed_after_screening: "closed_after_screening",
  appointment_scheduled: "appointment_scheduled",
  completed: "completed",
} as const;

export const PATIENT_TERMINATED_STATUS = "terminated";

export type PatientConsultationStatus =
  (typeof PATIENT_CONSULTATION_STATUS)[keyof typeof PATIENT_CONSULTATION_STATUS];

export const consultationStatusOptions: Option[] = Object.values(
  PATIENT_CONSULTATION_STATUS,
).map((status) => ({
  value: status,
  label: status.replace(/_/g, " "),
}));

export const consultationStatusGroupOptions: Group[] = [
  {
    group: "Status",
    groupOptions: consultationStatusOptions,
  },
] as const;
