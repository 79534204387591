import { generatePath, useParams } from "react-router-dom";

import {
  AudioQuestionData,
  CompletedAudioAnswer,
  ConsultationAudioAnswer,
} from "~/common/utils/consultations/shared";
import { intakeAudioPath } from "~/patients/api";
import { ConsultationAudioForm } from "~/patients/components/consultations/shared";
import {
  useDeleteConsultationAudioS3,
  useGetCurrentPatient,
  useUploadConsultationAudioS3,
} from "~/patients/hooks";

export interface IntakeAudioFormProps {
  question: AudioQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: ConsultationAudioAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer?: CompletedAudioAnswer;
  isOptional: boolean;
}

export const IntakeAudioForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  completedAnswer,
  disableBack,
  isOptional,
}: IntakeAudioFormProps) => {
  const { id: patientIntakeId } = useParams();
  const { data: patient } = useGetCurrentPatient();
  const consultationId = patient?.consultation?.id;

  const basePath = generatePath(intakeAudioPath, {
    consultationId: consultationId ?? "",
    patientIntakeId: patientIntakeId ?? "",
  });

  const { handleUploadAudio, pendingUploadAudio } =
    useUploadConsultationAudioS3(basePath);
  const { deleteAudioMutation, pendingDeleteAudio } =
    useDeleteConsultationAudioS3(basePath);

  return (
    <ConsultationAudioForm
      question={question}
      handleGoBack={handleGoBack}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      completedAnswer={completedAnswer}
      disableBack={disableBack}
      handleUploadAudio={handleUploadAudio}
      pendingUploadAudio={pendingUploadAudio}
      deleteAudioMutation={deleteAudioMutation}
      pendingDeleteAudio={pendingDeleteAudio}
      isOptional={isOptional}
    />
  );
};
