import { ScreeningIntakeMutationProps } from "~/common/utils";
import {
  AnswerIntakeQuestionProps,
  CompletePatientIntakeResponse,
  CurrentIntakeQuestion,
  IntakeDataResponse,
  IntakeQuestion,
  PatientIntakeBundle,
  StartPatientIntakeResponse,
} from "~/common/utils/consultations/intake/interfaces";
import { api } from "~/patients/api";
import {
  getConsultationBaseUrl,
  getPatientIntakePath,
} from "~/providers/utils";

export const intakeIntroScreenVisit = async () => {
  const response = await api.patch<void>(
    "/v1/patients/intake-intro-screen/visit",
    null,
  );
  return response.data;
};

export const getCurrentIntakeBundle = async (consultationId?: string) => {
  const { data } = await api.get<PatientIntakeBundle>(
    `${getConsultationBaseUrl(consultationId)}/intakes/current/bundles`,
  );
  return data;
};

export const getIntake = async (intakeId: string, consultationId?: string) => {
  const { data } = await api.get<IntakeDataResponse>(
    `${getPatientIntakePath(intakeId, consultationId)}`,
  );

  return data;
};

export const getCurrentIntakeQuestion = async (
  patientIntakeId: string,
  consultationId?: string,
) => {
  const { data } = await api.get<CurrentIntakeQuestion>(
    `${getPatientIntakePath(patientIntakeId, consultationId)}/current`,
  );

  return data;
};

export const answerIntakeQuestion = async ({
  answer,
  id: patientIntakeId,
  consultationId,
}: AnswerIntakeQuestionProps) => {
  const { data } = await api.post<string>(
    `${getPatientIntakePath(patientIntakeId, consultationId)}/answers`,
    answer,
  );

  return data;
};

export const getPreviousIntakeQuestion = async ({
  id: patientIntakeId,
  consultationId,
}: ScreeningIntakeMutationProps) => {
  const { data } = await api.post<IntakeQuestion>(
    `${getPatientIntakePath(patientIntakeId, consultationId)}/previous`,
    null,
  );

  return data;
};

export const startIntake = async ({
  id: intakeId,
  consultationId,
}: ScreeningIntakeMutationProps) => {
  const { data } = await api.post<StartPatientIntakeResponse>(
    `${getPatientIntakePath(intakeId, consultationId)}/start`,
  );

  return data;
};

export const completeIntake = async ({
  id: patientIntakeId,
  consultationId,
}: ScreeningIntakeMutationProps) => {
  const { data } = await api.post<CompletePatientIntakeResponse | null>(
    `${getPatientIntakePath(patientIntakeId, consultationId)}/complete`,
  );

  return data;
};
