export const CONSULTATION_ACTIONS = {
  closed_after_screening: "closed_after_screening",
  terminate: "terminate",
  reopen: "reopen",
  proceed_to_intake: "proceed_to_intake",
} as const;

export type PatientConsultationActions =
  (typeof CONSULTATION_ACTIONS)[keyof typeof CONSULTATION_ACTIONS];

export const REFER_PATIENT_PROVIDED = {
  referral_provided: "referral_provided",
  no_referral_provided: "no_referral_provided",
} as const;

export const REFER_PATIENT_PROVIDED_VALUES = [
  REFER_PATIENT_PROVIDED.referral_provided,
  REFER_PATIENT_PROVIDED.no_referral_provided,
] as const;
