import { api } from "~/patients/api";
import { getConsultationBaseUrl } from "~/providers/utils";

export const intakeAudioPath = `${getConsultationBaseUrl(
  ":consultationId",
)}/intakes/:patientIntakeId`;

export const screeningAudioPath = `${getConsultationBaseUrl(
  ":consultationId",
)}/screenings/:patientId`;

export const getConsultationPresignedUrl = async (basePath: string) => {
  const { data } = await api.get<string>(`${basePath}/presigned_url`);
  return data;
};

export const deleteConsultationAudio = async (basePath: string) => {
  const { data } = await api.delete<null>(`${basePath}/audio`);

  return data;
};
